import { GoogleAnalyticsEventsService } from './services/google-analytics-events.service';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule, ViewChild } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './Component/header/header.component';
import { FooterComponent } from './Component/footer/footer.component';
import { AboutusComponent } from './Component/footer/aboutus/aboutus.component';
import { AdsComponent } from './Component/ads/ads.component';
import { DownloadComponent } from './Component/footer/download/download.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './Component/home/home.component';
import { ProduitListComponent } from './Component/produit/produit-list/produit-list.component';
import { HeartstrokeComponent } from './Component/produit/heartstroke/heartstroke.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { SocialLoginModule, AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import {MatAccordion} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import {MatSliderModule} from '@angular/material/slider';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { LayoutModule } from '@angular/cdk/layout';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelect2Module } from 'ng-select2';
import { ListingProduitComponent } from './Component/produit/listing-produit/listing-produit.component';
import { ProduitInputComponent } from './Component/produit/produit-input/produit-input.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { DetailProduitComponent } from './Component/produit/detail-produit/detail-produit.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';
import { MdpoublierComponent } from './Component/mdpoublier/mdpoublier.component';
import { PanierComponent } from './Component/panier/panier.component';
import { DetailVendeurComponent } from './Component/vendeur/detail-vendeur/detail-vendeur.component';
import { DevenirvendeurComponent } from './Component/vendeur/devenirvendeur/devenirvendeur.component';
import { LoginDevenirVendeurComponent } from './Component/vendeur/login-devenir-vendeur/login-devenir-vendeur.component';
import { AjoutProdMobComponent } from './Component/produit/ajout-prod-mob/ajout-prod-mob.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { AuthInterceptor } from './interceptor/httpconfig.interceptor';
import { Step1Component } from './Component/produit/ajout-prod-mob/step1/step1.component';
import { Step2Component } from './Component/produit/ajout-prod-mob/step2/step2.component';
import { Step3Component } from './Component/produit/ajout-prod-mob/step3/step3.component';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import {PlatformModule} from '@angular/cdk/platform';
import {MatBadgeModule} from '@angular/material/badge';

import { Ng5SliderModule } from 'ng5-slider';
import { MatVideoModule } from 'mat-video';
import {CommonModule, DecimalPipe} from '@angular/common';
// import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';

import { ImageCropperModule } from 'ngx-image-cropper';

// ngx-bootstrap
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MoncompteComponent } from './Component/moncompte/moncompte.component';
import { ProfilComponent } from './Component/moncompte/profil/profil.component';
import { CommandeComponent } from './Component/moncompte/commande/commande.component';
import { MessageComponent } from './Component/moncompte/message/message.component';
import { AchatComponent } from './Component/moncompte/achat/achat.component';
import { EnviesComponent } from './Component/moncompte/envies/envies.component';
import { BoutiqueComponent } from './Component/moncompte/boutique/boutique.component';
import { CompteNavComponent,DialogConfirmation } from './Component/moncompte/compte-nav/compte-nav.component';
import { AdrsLivraisonComponent } from './Component/panier/adrs-livraison/adrs-livraison.component';
import { PaymentComponent } from './Component/panier/payment/payment.component';
import { SuccessComponent } from './Component/panier/success/success.component';
import { LoginComponent } from './Component/login/login.component';

import { MessagedetailComponent } from './Component/moncompte/messagedetail/messagedetail.component';
import { MenuItemComponent } from './Component/header/menu-item/menu-item.component';

import { NgxSocialShareModule } from 'ngx-social-share';
import { DetailCommandeComponent } from './Component/moncompte/commande/detail-commande/detail-commande.component';
import { PromotionBoutiqueComponent } from './Component/moncompte/promotion-boutique/promotion-boutique.component';
import { ListeByCodeComponent } from './Component/moncompte/achat/liste-by-code/liste-by-code.component';
import { ConsulterComponent } from './Component/moncompte/achat/consulter/consulter.component';
import { MaListeComponent } from './Component/moncompte/achat/ma-liste/ma-liste.component';
import { VenteListComponent } from './Component/moncompte/commande/vente-list/vente-list.component';
import { DetailVenteComponent } from './Component/moncompte/commande/vente-list/detail-vente/detail-vente.component';
import { DateFormatDirective } from './directives/date-format.directive';
import { OnlyDigitDirective } from './directives/only-digit.directive';
import { DetailComponent } from './Component/moncompte/achat/consulter/detail/detail.component';
import { CguComponent } from './Component/cgu/cgu.component';
import { CguAppComponent } from './Component/cgu/cgu-app/cgu-app.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { CentreDAideComponent } from './Component/centre-d-aide/centre-d-aide.component';

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { VjsPlayerComponent } from './Component/vjs-player/vjs-player.component';
import { ResultSearchComponent } from './Component/result-search/result-search.component';
import { ChatService } from './services/chat.service';
import { DatePipe } from '@angular/common';
// import { TransferHttpCacheModule } from '@nguniversal/common';
import { PageDynamiqueComponent } from './Component/page-dynamique/page-dynamique.component';
import { OptinComponent } from './Component/common/optin/optin.component';
import { MinicardGroupComponent } from './Component/common/minicard-group/minicard-group.component';
import { HeroComponent } from './Component/common/hero/hero.component';
import { ExpandableComponent } from './Component/common/expandable/expandable.component';
import { TestimonialComponent } from './Component/common/testimonial/testimonial.component';
import { ContactComponent } from './Component/common/contact/contact.component';
import { CardComponent } from './Component/common/card/card.component';
import { environment } from 'src/environments/environment';
import { FraisLivraisonComponent, DialogFraisDialog } from './Component/moncompte/frais-livraison/frais-livraison.component';
// tslint:disable-next-line:max-line-length
import { DialogFraisLivraisonDialogComponent } from './Component/common/dialog-frais-livraison-dialog/dialog-frais-livraison-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AbonnementComponent, DialogVoirPlus } from './Component/vendeur/abonnement/abonnement.component';
import { DialogPayementDialogComponent } from './Component/common/dialog-payement-dialog/dialog-payement-dialog.component';
import { UniverCategorieComponent } from './Component/univer-categorie/univer-categorie.component';
import { MentionsLegalesComponent } from './Component/mentions-legales/mentions-legales.component';
import { UniverPipePipe } from './Component/univer-categorie/univer-pipe.pipe';

import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { PanierNewComponent } from './Component/panier-new/panier-new.component';
import { ModalImageOrderComponent, DialogOverviewConfirmDialog } from './Component/modal-image-order/modal-image-order.component';
import { ModalUploadFileComponent } from './Component/modal-upload-file/modal-upload-file.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    // domain: 'http://localhost'// it is recommended to set your domain, for cookies to work properly
    domain: 'https://hadeen-place.fr'// it is recommended to set your domain, for cookies to work properly
  },
  palette: {
    popup: {
      background: '#42594F'
    },
    button: {
      background: '#DDB254'
    }
  },
  theme: 'block',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": `
      {{messagelink}}{{compliance}}
    `
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message py-3">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>, 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>.
      </span>
    `,
  },
  content:{
    message: 'Nous utilisons des cookies pour mesurer notre audience et assurer un fonctionnement optimale de notre site. Vous avez la possibilité de les accepter ou de les refuser. Plus d’informations, consultez nos ',
    privacyPolicyLink: 'informations légales',
    privacyPolicyHref: 'https://hadeen-place.fr/mentions-legales',
    tosLink: 'CGU - CGV',
    tosHref: 'https://hadeen-place.fr/conditions-de-ventes',
  }
};

// import { registerLocaleData } from '@angular/common';
// import localeFr from '@angular/common/locales/fr';
// registerLocaleData(localeFr, 'fr');

// import 'hammerjs';
// import 'mousetrap';
// import { GalleryModule } from '@ks89/angular-modal-gallery';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(`${environment.googleLog}`)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(`${environment.facebookLog}`)
  }
]);
export function socialConfig() {
  return config;
}
// export class ExpansionExpandCollapseAllExample {
//   @ViewChild(MatAccordion) accordion: MatAccordion;
// }
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AboutusComponent,
    AdsComponent,
    DownloadComponent,
    HomeComponent,
    ProduitListComponent,
    HeartstrokeComponent,
    ListingProduitComponent,
    ProduitInputComponent,
    DetailProduitComponent,
    MdpoublierComponent,
    PanierComponent,
    DetailVendeurComponent,
    DevenirvendeurComponent,
    LoginDevenirVendeurComponent,
    AjoutProdMobComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    MoncompteComponent,
    ProfilComponent,
    CommandeComponent,
    MessageComponent,
    AchatComponent,
    EnviesComponent,
    BoutiqueComponent,
    CompteNavComponent,DialogConfirmation,
    AdrsLivraisonComponent,
    PaymentComponent,
    SuccessComponent,
    LoginComponent,
    MessagedetailComponent,
    MenuItemComponent,
    DetailCommandeComponent,
    PromotionBoutiqueComponent,
    ListeByCodeComponent,
    ConsulterComponent,
    MaListeComponent,
    VenteListComponent,
    DetailVenteComponent,
    DateFormatDirective,
    OnlyDigitDirective,
    DetailComponent,
    CguComponent,
    CguAppComponent,
    CentreDAideComponent,
    SafePipe,
    SafeHtmlPipe,
    VjsPlayerComponent,
    ResultSearchComponent,
    PageDynamiqueComponent,
    OptinComponent,
    MinicardGroupComponent,
    HeroComponent,
    ExpandableComponent,
    TestimonialComponent,
    ContactComponent,
    CardComponent,
    FraisLivraisonComponent,
    DialogFraisDialog,
    DialogFraisLivraisonDialogComponent,
    AbonnementComponent, DialogVoirPlus,
    DialogPayementDialogComponent,
    UniverCategorieComponent,
    MentionsLegalesComponent,
    UniverPipePipe,
    PanierNewComponent,
    ModalImageOrderComponent, DialogOverviewConfirmDialog,
    ModalUploadFileComponent
  ],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    // TransferHttpCacheModule,
    // BrowserTransferStateModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    GooglePlaceModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }),
    PopoverModule.forRoot(),
    CarouselModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SocialLoginModule,
    LayoutModule,
    NgxPaginationModule,
    MatSliderModule, MatExpansionModule, MatTabsModule, MatSidenavModule,
    MatDatepickerModule, MatButtonModule, MatInputModule, MatPaginatorModule,
    MatCardModule, MatDividerModule, MatListModule, MatIconModule,
    MatRadioModule, MatDialogModule, MatAutocompleteModule, MatSlideToggleModule,
    MatStepperModule, MatTableModule, MatMenuModule, MatNativeDateModule,
    MatTooltipModule, MatSelectModule,
    NgSelect2Module,
    NgMultiSelectDropDownModule.forRoot(),
    MatProgressSpinnerModule,
    MatCheckboxModule,
    Ng5SliderModule,
    NgxSocialShareModule,
    ImageCropperModule,
    MatGridListModule,
    MatBadgeModule,
    MatVideoModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    PlatformModule, CommonModule
  ],
  providers: [
    {provide: 'googleTagManagerId', useValue: 'GTM-T4VRQP2'},
    // { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    ChatService,
    HttpClient,
    DecimalPipe,
    DatePipe,
    {
      provide: AuthServiceConfig,
      useFactory: socialConfig
    },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    },
    MatNativeDateModule,
    // NgxImageCompressService
    GoogleAnalyticsEventsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
