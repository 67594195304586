import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { ModalUploadFileComponent } from '../modal-upload-file/modal-upload-file.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-image-order',
  templateUrl: './modal-image-order.component.html',
  styleUrls: ['./modal-image-order.component.scss']
})
export class ModalImageOrderComponent implements OnInit {
  dataDefault: any = [];
  dataLoc: any = [];
  imageDeleted: any = []

  constructor(
    private httpService: HttpService, public dialog: MatDialog, public dialogRef: MatDialogRef<ModalImageOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private toastrService: ToastrService
  ) {
    console.log('data order image: ', data.product.id.produitId);
    
  }

  ngOnInit(): void {
    this.imagesGet();
  }

  imagesGet() {
    this.httpService.get(`image-produits/HADEEN/${this.data.product.id.produitId}`).subscribe(
      (resp: any) => {
        this.dataDefault = resp.data;
        let dataOrdered = this.groupBy(resp.data, 'couleur', 'id');
        this.dataLoc = this.convertObjectToArray(dataOrdered);
      },
      err => {
        let message = err?.error?.data || 'Une erreur est survenue. Nous vous invitons à réessayer plus tard.';
        this.toastrService.error(message);
        this.onNoClick()
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addImage(): void {
    const formData = new FormData();

    this.httpService.get('').subscribe((resp: any) => {
      console.log('images');
    })
  }

  groupBy(array, key, key1) {
    return array.reduce((result, item) => {
      // Récupérer la valeur de la clé spécifiée
      const groupKey = item[key][key1];
  
      // Vérifier si la clé existe déjà dans le résultat, sinon l'initialiser
      if (!result[groupKey]) {
        // result[groupKey] = [];
        result[groupKey] = [];
      }
  
      // Ajouter l'objet actuel au bon groupe
      result[groupKey].push(item);
  
      return result;
    }, {}); // Initialiser le résultat avec un objet vide
  }

  convertObjectToArray(obj) {
    // Vérifie que le paramètre est un objet
    if (typeof obj !== 'object' || obj === null) {
      throw new Error('Le paramètre doit être un objet.');
    }
  
    // Retourne uniquement les valeurs de l'objet sous forme de tableau
    return Object.values(obj);
  }
  
  imagesUpdateOrder(_images) {
    const formData = new FormData();
    _images.forEach((elt, idx) => {
      formData.set(`imgs[${idx}].order`,elt.order);
      formData.set(`imgs[${idx}].img_id`,elt.id);
    });
    this.httpService.post(`image-produits/update-order`, formData).subscribe(
      (resp: any) => {
        this.imagesGet()
      },
      err => {
        let message = err?.error?.data || 'Une erreur est survenue. Nous vous invitons à réessayer plus tard.';
        this.toastrService.error(message);
      }
    );
  }

  openDialog(_images: any): void {
    const dialogRef = this.dialog.open(ModalUploadFileComponent, {
      width: '250px',
      data: {productId: this.data.product.id.produitId, images: _images, allImages: this.dataDefault}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed: ', result);
      if (result.message) {
        this.imagesGet();
      }
    });
  }

  openConfirmDialog(_imageId): void {
    console.log('asasa', _imageId);
    
    const dialogRef = this.dialog.open(DialogOverviewConfirmDialog, {
      width: '250px',
      data: _imageId
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed: ', result);
      if (result.message) {
        this.imagesGet();
      }
    });
  }
}

@Component({
  selector: 'dialog-overview-confirm-dialog',
  templateUrl: 'dialog-overview-confirm-dialog.html',
})
export class DialogOverviewConfirmDialog {

  constructor(private httpService: HttpService, private toastrService: ToastrService,
    public dialogRef: MatDialogRef<DialogOverviewConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  imageRemove() {
    this.httpService.delete(`image-produits/${this.data.id}`).subscribe(
      (resp: any) => {
        this.dialogRef.close({message: 'success'});
      },
      err => {
        let message = err?.error?.data || 'Une erreur est survenue. Nous vous invitons à réessayer plus tard.';
        this.toastrService.error(message);
      }
    );
  }
}