<div class="file-upload-container">
  <input type="file" id="fileInput" #fileInput (change)="onFileSelected($event)" multiple class="d-none" />
  <div class="file-upload-container" (click)="fileInput.click()">
    <div *ngIf="imagePreview; else loggedOut">
      <img [src]="imagePreview" alt="Image Preview" class="image-preview" style="aspect-ratio: 1/1.2; object-fit: contain;" />
    </div>
    
    <ng-template #loggedOut>
      <!-- <button class="btn btn-outline-dark">Ajouter une image</button> -->
    </ng-template>
  </div>

  <div *ngIf="imagePreview">
    Ordre: <input type="number" [defaultValue]="data.images.length + 1" [(ngModel)]="order" style="width: 50px;" class="m-auto text-center border-bottom">

    <div class="mt-2" style="color: red;">
      <p class="mb-2" style="font-size: 12px; line-height: 12px;">{{messageError}}</p>
      <p style="font-size: 12px; line-height: 12px;">{{messageSuggestion}}</p>
    </div>
  </div>
</div>

<mat-dialog-actions class="mt-2 justify-content-center" *ngIf="imagePreview">
  <button class="btn btn-outline-success" mat-button (click)="imageAdd()">Ajouter l'image</button>
</mat-dialog-actions>
