<h2 mat-dialog-title>Liste des images du produit ID: {{data.product.id.produitId}}</h2>
<mat-dialog-content>
  <div class="border mb-2 pb-2" *ngFor="let imagesByColor of dataLoc">
    <div *ngIf="imagesByColor[0].couleur" class="row pl-4 pt-2">
      <div class="mr-2">{{imagesByColor[0].couleur.nom}}</div>
      <div class="div-color" *ngIf="imagesByColor[0].couleur.codeHexa" [style.background-color]="imagesByColor[0].couleur.codeHexa"></div>
      <div class="div-color" *ngIf="!imagesByColor[0].couleur.codeHexa" class="multicolor"></div>
    </div>
    
    <div class="row m-0 px-2">
      <div *ngFor="let img of convertObjectToArray(imagesByColor)" class="col-4 col-md-3 text-center mt-2 px-1" style="position: relative;">
        <input type="number" [(ngModel)]="img.order" style="width: 50px;" class="m-auto text-center border-bottom">
        <div class="border" style="aspect-ratio: 1/1.2">
          <img [src]="img.image" alt="" style="max-height: 100%;">
        </div>
        <div class="text-center">
          <button mat-icon-button [disabled]="convertObjectToArray(imagesByColor).length <= 1" (click)="openConfirmDialog(img)" aria-label="Example icon button with a vertical three dot icon">
            <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 text-center mt-2">
      <button class="btn btn-outline-secondary btn-sm rounded-0 mr-2" (click)="imagesUpdateOrder(imagesByColor)">Modifier l'ordre</button>
      <button class="btn btn-outline-secondary btn-sm rounded-0" (click)="openDialog(convertObjectToArray(imagesByColor))">+</button>
    </div>

  </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-center">
  <button mat-button class="btn btn-danger rounded-0" (click)="onNoClick()" cdkFocusInitial>Annuler</button>
  <!-- <button mat-button [mat-dialog-close]="maj()" cdkFocusInitial>Mettre à jours</button> -->
</mat-dialog-actions>
