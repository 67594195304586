import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-modal-upload-file',
  templateUrl: './modal-upload-file.component.html',
  styleUrls: ['./modal-upload-file.component.scss']
})
export class ModalUploadFileComponent implements AfterViewInit {
  messageError = '';
  messageSuggestion = '';
  imagePreview: string | ArrayBuffer | null = null; // Image preview data
  selectedFile: File | null = null; // The selected file
  order: any;
  @ViewChild('fileInput') fileInputRef: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ModalUploadFileComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private httpService: HttpService, private toastrService: ToastrService
  ) {
    // this.order = data.images.length + 1;

    let imgAllOrder: any = [...new Set(this.data.allImages.map((elt) => elt.order))];
    let imgLength: number = imgAllOrder.length;
    let orderDispo: any = this.trouverTousLesDisponibles(imgAllOrder, 1, imgAllOrder[imgLength - 1]);
    let orderLast = imgAllOrder[imgLength - 1] || 0;
    if (orderDispo.length) {
      this.order = orderDispo[0];
    } else {
      this.order = orderLast + 1;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // Triggered when file input changes
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];

      // Generate a preview for the selected file
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result; // Set the image preview
      };
      reader.readAsDataURL(this.selectedFile); // Convert file to Data URL
    }
  }

  imageAdd() {
    this.messageError = '';
    this.messageSuggestion = '';
    let orderExist = this.data.allImages.filter((elt: any) => elt.order == this.order);
    
    if (orderExist.length) {
      this.messageError = `l'ordre ${this.order} à été déjà utilisé sur l'un des images dans ce produit, merci de choisir une autre numero`;

      let imgAllOrder: any = [...new Set(this.data.allImages.map((elt) => elt.order))];
      let imgLength: number = imgAllOrder.length;
      let orderDispo: any = this.trouverTousLesDisponibles(imgAllOrder, 1, imgAllOrder[imgLength - 1]);
      let orderLast = imgAllOrder[imgLength - 1] || 0;
      this.messageSuggestion = `suggestion de(s) ordre(s) disponnible: ${orderDispo.join(", ")} ,${orderLast + 1} ou plus`;
    } else {
      const formData = new FormData();
      formData.set('order', orderExist.length ? this.data.allImages.length + 1 : this.order);
      formData.set('images[0]', this.selectedFile);
      if (this.data?.images[0]?.couleur) {
        formData.set('couleur', this.data.images[0].couleur.id);
      }
      this.httpService.post(`image-produits/HADEEN/${this.data.productId}`, formData).subscribe(
        (respo: any) => {
          this.dialogRef.close({message: 'success'});
        },
        err => {
          let message = err?.error?.data || 'Une erreur est survenue. Nous vous invitons à réessayer plus tard.';
          this.toastrService.error(message);
        }
      )
    }
  }

  ngAfterViewInit(): void {
    this.fileInputRef.nativeElement.click()
  }

  trouverTousLesDisponibles(liste, debut, fin) {
    let nombresDisponibles = [];
    for (let i = debut; i <= fin; i++) {
        if (!liste.includes(i)) {
            nombresDisponibles.push(i); // Ajoute les nombres manquants
        }
    }
    return nombresDisponibles;
  }
}
