<h1 mat-dialog-title>Voulez vous supprimer cet image?</h1>
<div mat-dialog-content>
  <div class="ratio" style="aspect-ratio: 1/1.2;">
    <img [src]="data.image" alt="">
  </div>
</div>
<div mat-dialog-actions class="justify-content-between">
  <button mat-button (click)="onNoClick()">Annuler</button>
  <button mat-button class="btn btn-outline-danger" (click)="imageRemove()" cdkFocusInitial>Supprimer</button>
  <!-- <button mat-button class="btn btn-outline-danger" [mat-dialog-close]="imageRemove()" cdkFocusInitial>Supprimer</button> -->
</div>